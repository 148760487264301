import React from 'react';
import PropTypes from 'prop-types';
import ChevronRight16 from '@andes/icons/ChevronRight16';
import { useRenderContext } from '../../pages/home/context';

const { rebuildChildrenProps } = require('eshops-components-library').default;

const RecommendationContainer = ({ title, see_more: seeMore, children, nodeItems }) => {
  if ((nodeItems.length > 0) && (nodeItems[0]?.properties?.recommendations?.recommendations_size < 5)) {
    return null;
  }
  if (!seeMore) {
    return null;
  }

  const { device, marketplaceInfo: { isWebview } } = useRenderContext();
  const newChildren = rebuildChildrenProps({ children, isWebview });

  const { text: seeMoreText, link: seeMoreLink } = seeMore || {};
  const namespace = 'ui-recos-carousel-wrapper';
  const layout = device === 'mobile' ? 'column' : 'row';

  const seeMoreBtn = device === 'mobile' ? <ChevronRight16 /> : seeMoreText;

  return (
    <div className={`${namespace} ${namespace}--${layout}`}>
      <div className={`${namespace}__header`}>
        <h2 className={`${namespace}__title`}>{title}</h2>
        <a href={seeMoreLink} className={`${namespace}__link`}>{seeMoreBtn}</a>
      </div>
      {newChildren}
    </div>
  );
};

RecommendationContainer.propTypes = {
  title: PropTypes.string.isRequired,
  see_more: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
  }),
  device: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
  nodeItems: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
};

export default RecommendationContainer;
